import { OAuth2Agent } from '@seplag/acesso-cidadao-oauth2-lib';

export const authParams = {
  appName: 'BIG DATA SOCIAL',
  appVersion: '1.0',
  appKey: process.env.REACT_APP_BIG_DATA_APP_KEY,
  loginWebApp: process.env.REACT_APP_LOGIN_URL,
  loginServer: process.env.REACT_APP_ACESSO_API_URL,
  redirectEnabled: true
};

export const oauth2Agent = new OAuth2Agent(authParams);
