import { useEffect, useState } from 'react';
//import { cmic, ticketGas } from '@/services';
import { ticketGas } from '@/services';
import { dotsNumber } from '@/utils/string';

import { Box } from './Summary.styles';
import { BenefitsCard } from './BenefitsCard';
import { Loading } from '@/components/shared';

export function Summary() {
  /** 
   * const [familiesAmount, setFamiliesAmount] = useState(0);
  const [familiesAmountLoading, setFamiliesAmountLoading] = useState(false);
  **/

  const [ticketGasAmount, setTicketGasAmount] = useState(0);
  const [ticketGasAmountLoading, setTicketGasAmountLoading] = useState(false);

  /**
   * const [investedMoney, setInvestedMoney] = useState(0);
  const [investedMoneyLoading, setInvestedMoneyLoading] = useState(false);
  
  useEffect(() => {
    setFamiliesAmountLoading(true);
    cmic
      .amount()
      .then((data) => setFamiliesAmount(data))
      .finally(() => setFamiliesAmountLoading(false));
  }, []);
     */

  useEffect(() => {
    setTicketGasAmountLoading(true);
    ticketGas
      .amount()
      .then((data) => setTicketGasAmount(data))
      .finally(() => setTicketGasAmountLoading(false));
  }, []);

  /**

  useEffect(() => {
    setInvestedMoneyLoading(true);
    cmic
      .getInvestedMoney()
      .then((data) => setInvestedMoney(data))
      .finally(() => setInvestedMoneyLoading(false));
  }, []);
   */

  const nFamilias = dotsNumber(150000);
  const nCriancas = `300.000`;
  const nInvestido = `R$ ${dotsNumber(193000000)}`;
  const nValeGas = dotsNumber(ticketGasAmount);

  /** const loading = familiesAmountLoading || ticketGasAmountLoading || investedMoneyLoading; */
  const loading = ticketGasAmountLoading;

  function renderContent() {
    return (
      <>
        <div className="benefits">
          <BenefitsCard amount={nFamilias} text="famílias">
            <p>
              são beneficiadas pelo Cartão Mais Infância Ceará
              <br />
              <span>* 10 mil estão em processo de validação</span>
            </p>
          </BenefitsCard>

          <BenefitsCard
            amount={nCriancas}
            text="crianças"
            prefix="Aproximadamente"
          >
            <p>já foram beneficiadas pelo Programa Mais Infância.</p>
          </BenefitsCard>
        </div>

        <div className="benefits">
          <BenefitsCard amount={nInvestido} text="investidos">
            <p>por ano para melhorar a qualidade de vida dessas famílias.</p>
          </BenefitsCard>

          <BenefitsCard amount={nValeGas} text="famílias">
            <p>já foram beneficiadas pelo Programa Vale Gás Social.</p>
          </BenefitsCard>
        </div>
      </>
    );
  }

  return <Box>{loading ? <Loading /> : renderContent()}</Box>;
}
